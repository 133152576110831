<!-- 考试倒计时组件 -->
<template>
<div >
    <el-container   style="margin-left: 1px;">
        <el-header class="header_style" style="line-height: 40px;height: 40px;"><font size="3">剩余时间：</font></el-header>
    <el-main class="content_style">
        <el-row style="margin-top: -10px;display:flex;flex-wrap: wrap;" >
            <p class="time">{{timerCount2}}分{{timerCount1}}秒</p>
        </el-row>
        <el-row style="margin-top: -10px;display:flex;flex-wrap: wrap;">
            <span><font size="3">{{exam_title}}</font></span>
        </el-row>
    </el-main>
    <el-footer>
    <el-button class="button_style" type="primary" @click="reset">交卷</el-button>
    </el-footer>
    </el-container>
</div>
</template>

<script>
export default {
    data(){
        return{
            timeSeconds: 0,
            timeMinutes: 0,
            seconds: 0, // 秒
            minutes:0, //分钟
            exam_title:""
        }
    },
    props:['parent_msg'],
    methods:{
        beginTiming(tm){
            this.timeMinutes = tm;
            this.minutes = tm;
            this.timing();
        },
        showTitle(obj){
            this.exam_title=obj;
        },
        num(n){
            return n < 10 ? "0" + n : "" + n;
        },
        // 重新计时
        reset(){
            sessionStorage.removeItem("answered");
            //window.location.reload();
            localStorage.removeItem("startTime1");
            clearInterval(this.timer);
            this.timeSeconds = 0;
            this.clear();
            this.$emit("HandPaper");
        },
        // 清除
        clear(){
            localStorage.removeItem("startTime1");
            sessionStorage.setItem("answered", 1);
            clearInterval(this.timer);
        },
        // 倒计时
        timing(){
            let [startTime1] = [ localStorage.getItem("startTime1")];
            let nowTime = new Date().getTime();
            if (startTime1){
                //点击刷新时可能出错,因为前面的没有走完，则重新开始时，就会出错
                let surplus = (this.minutes*60+this.seconds)-parseInt((nowTime - startTime1) / 1000);
                this.timeMinutes = surplus <= 0?0:parseInt(surplus/60);
                this.timeSeconds = surplus <= 0 ? 0 : (surplus-60*this.timeMinutes);
            }
            else{
                localStorage.setItem("startTime1", nowTime); //存储秒
            }
            
            this.timer = setInterval(() => {
                if ( this.timeSeconds == 0 && this.timeMinutes != 0 && this.timeMinutes > 0 ){
                    let nowTime = new Date().getTime();
                    if (this.timeSeconds===0)
                      this.timeSeconds=59;
                    localStorage.setItem("startTime1", nowTime);
                    this.timeMinutes--;
                }
                else if (this.timeMinutes == 0 && this.timeSeconds == 0){
                    this.timeSeconds = 0;
                    this.clear();
                    this.$emit("TimeHand");
                    //alert("考试时间到");
                }
                else{
                    this.timeSeconds--;
                }
            }, 1000);
        }
    },
    mounted(){
        // this.timeMinutes = this.$props.parent_msg;
        // this.minutes = this.$props.parent_msg;
        // this.timing();
        // if (sessionStorage.getItem("answered") != 1){
        //     this.timing();
        // }
    },
    computed:{
        timerCount1(){
            return this.timeSeconds < 10 ? "0" + this.timeSeconds : "" + this.timeSeconds;
        },
        timerCount2(){
            return this.timeMinutes < 10 ? "0" + this.timeMinutes : "" + this.timeMinutes;
        }
    },
    unmounted() {
        // 退出后清除计时器
        if (this.timer){
            clearInterval(this.timer);
        }
    }
}
</script>

<style scoped>
.time {
 color: #f72a3a;
 font-weight: bold;
 font-size: 20px;
}
.header_style {
		margin-top: 0px;
		border: 1px solid rgb(228, 228, 228);
		text-align: left;
		padding-left: 10px;
	}

	.content_style {
		border: 1px solid rgb(228, 228, 228);
		text-align: left;
		padding-left: 10px;
        
	}

	.button_style {
		margin-top: 10px;
		margin-left: -10px;
		width: 150px;
		text-align: middle;
        
	}
</style>