<template>
    <el-row style="display: block;margin-top:0px;">
        <el-row>  <!--试卷头部分-->
          <div class="other_header" style="background:rgba(200, 222, 231,1);z-index: 200;height: 50px;line-height: 50px;text-align: center; color:#5D9CEC ;width:100%;">
            <el-row>
            <el-col :span="1">
              <img style="width:40px;height:40px;margin-top:5px;" src="../../assets/cts.png" />
            </el-col>
            <el-col :span="22">
              <span><font color="black">{{paperName}}</font></span>
            </el-col>
            <el-col :span="1">
              <img style="width:40px;height:40px;" src="../../assets/user.png" />
            </el-col>
            </el-row>
          </div>
      </el-row>

      <el-row class="backstyle" >
          <!--题目列表序号区-->
        <el-col :span="5">
          <div style="width:300px;">
          <el-container   :style="height">
            <el-header class="header_style" style="line-height: 40px;height: 40px;"><font size="3">答题卡</font></el-header>
            <el-main class="content_style" style="margin-left: 0px;display: block;overflow-y: scroll;">
              
              <el-row  style="margin-top: -20px;display:flex;flex-wrap:wrap;" >
                <div v-for="(item,index) in arrProblems" :key="index">
                  <el-button class="button_style"  :style="item.arrExams[0].show" @click="GetProblem(index);">{{item.arrExams[0].seq_group}}</el-button>
                </div>
              </el-row>
            </el-main>
          </el-container>
          </div>
        </el-col>

        <!--中间试题区-->
        <el-col :span="16" style="min-height: 100%;">
            <el-container :style="height_content">
                <el-main class="content_style" style="margin-left: 0px;display: block;overflow-y: scroll;">
                    <div v-for="(item,index) in arrProblems" :key="index" >
                      <div v-if="currTestIndex==index">  
                            <div  v-for="(member,midx) in item.arrExams" :key="midx" style="width:100%">
                            <el-row :class="`classabc${item.seq_group}`" style="margin-bottom: 10px;">
                              
                              <el-col :span="2" >
                                <span v-if="member.exam_type==1528" class="i_style">{{item.seq_group}}<label style="font-size: xx-small;">&#160;单选</label></span>
                                <span v-else-if="member.exam_type==1530" class="i_style">{{item.seq_group}}<label style="font-size: xx-small;">&#160;判断</label></span>
                                <span v-else-if="member.exam_type==1529" class="i_style">{{item.seq_group}}<label style="font-size: xx-small;">&#160;多选</label></span>
                                <span v-else-if="member.exam_type==1531" class="i_style">{{item.seq_group}}<label style="font-size: xx-small;">&#160;填空</label></span>
                                <span v-else-if="member.exam_type==1652" class="i_style">{{item.seq_group}}<label style="font-size: xx-small;">&#160;问答</label></span>
                              </el-col>

                              <el-col :span="21" style="margin-left:-20px;">
                                
                                <el-row  style="rgb(228,228,228);">
                                 <span style="font-family: sans-serif;white-space:pre-wrap;"><font size="3"> {{member.exams}}</font></span>
                                </el-row>
                                

                                

                                <el-row style="margin-top: 20px;">
                                  <div v-if="member.exam_type==1528||member.exam_type==1530"> <!--单选题，判断题-->
                                  <el-radio-group style="margin-top: 0px;" v-model="member.arrAnswers" @change='selchange(index,midx)'>
                                    <div v-for="(citem,idx) in member.arrResults" :key="idx"> 
                                    <el-radio  style="margin-top: 8px;" :label="citem.id">
                                      <span style="white-space: pre-wrap;font-family: sans-serif;"><font size="3">{{citem.title}}</font></span>
                                     </el-radio>
                                    </div>
                                    </el-radio-group>
                                  </div>
                                  <div v-else-if="member.exam_type==1529"> <!--多选题-->
                                      <el-checkbox-group style="margin-top: 0px;" v-model="member.arrAnswers" @change='selchange(index,midx)'>
                                      <div v-for="(citem,idx) in member.arrResults" :key="idx"> 
                                          <el-checkbox style="margin-top: 8px;" :label="citem.id">
                                            <span style="white-space: pre-wrap;font-family: sans-serif;"><font size="3"> {{citem.title}}</font></span>
                                          </el-checkbox>
                                      </div>
                                  </el-checkbox-group>
                                  </div>
                                  <div v-else-if="member.exam_type==1531"> <!--填空题，添加文本框-->
                                    <span style="white-space: pre-wrap;"><font size="3"> {{citem.title}}&nbsp;&nbsp;&nbsp;&nbsp;</font></span>
                                    <span v-show="!member.edit">(点击输入答案!)</span>
                                        <el-input v-show="member.edit" v-model="citem.id" style="width:200px;"></el-input>
                                        <i
                                          :class="{'el-icon-edit': !member.edit, 'el-icon-check': member.edit}"
                                          @click="Fillchange(member.index,index,midx)"
                                        ></i>
                                  </div>
                                  <div v-else-if="member.exam_type==1652"> <!--问答题,添加文本框-->
                                      <span v-show="!member.edit">(点击输入答案!)</span>
                                        <el-input v-show="member.edit" v-model="member.myAnswer" type="textarea" rows="3" style="width:500px;"></el-input>
                                        <i
                                          :class="{'el-icon-edit': !member.edit, 'el-icon-check': member.edit}"
                                          @click="Answerchange(member.index,index,midx)"
                                        ></i>
                                  </div>
                                </el-row>
                                
                                
                              </el-col>
                              
                            </el-row>
                            </div>
                      </div>  


                    </div>
                </el-main>
                <el-footer style="margin-top:1px;">
                    <el-row style="position:fixed;bottom:250px;" :gutter="20">
                              <el-col :span="12" style="text-align: right;">
                                <el-button @click="GetPrevProblem();" style="width:100px;" type="primary" >上一题</el-button>
                              </el-col>
                              <el-col :span="12" style="text-align: left;">
                                <el-button type="primary" style="width:100px;" @click="GetNextProblem();">下一题</el-button>
                              </el-col>
                            </el-row>
                </el-footer>
            </el-container>    
        </el-col>

        <!--定时器-->
        <el-col :span="3">
            <div style="position:fixed;width:180px;">
              <TimeCount  ref="TimeCount" :parent_msg="time_length" @HandPaper="doHandIn" @TimeHand="doHandWork"></TimeCount>
            </div>
        </el-col>
      </el-row>

    </el-row>
    <el-row style="margin-top: 60px;text-align: center;">
      <el-col :span="3">
        <div>
          <div class="mask" v-if="showModal" @click="showModal=false"></div>
          <div class="pop" v-if="showModal" style="display:inline-block;text-align:center;">
            <div><img src="../../assets/question.png" /></div>
            <div style="margin-top:10px;"><span><font size="3">确认交卷吗？</font></span></div>
            <div style="margin-top:40px;">
              <el-button  type="success" style="width:100px;" @click="doHandWork();">确定</el-button>
              <el-button  type="info" style="width:100px;margin-left:50px;" @click="showModal=false">取消</el-button>
            </div>
          </div>
          
        </div>
      </el-col>
      
    </el-row>
</template>

<style lang="less" scoped>
  .header_style {
		margin-top: 0px;
		border: 1px solid rgb(228, 228, 228);
		text-align: left;
		padding-left: 10px;
	}

	.other_header {
		background: rgb(255, 255, 255);
		font-size: 20px;
		font-weight: 400px;
		color: rgb(143, 201, 211);

	}

.backstyle{
  width: 100%;
  margin-top:-18px;
  z-index: 100;
}
	.content_style {
		border: 1px solid rgb(228, 228, 228);
		text-align: left;
		padding-left: 10px;
        
	}

	.button_style {
		margin-top: 10px;
		margin-left: 18px;
		margin-left: 10px;
		width: 50px;
		text-align: center;
    color: rgb(10, 10, 10);
    background:rgba(231, 236, 240,1);
	}

.button_style:hover{
  color:pink;
  background: rgb(186, 240, 222);
}

.button_style:active{
  color: rgb(248, 4, 4);
}

	.i_style {
		background: rgb(190, 209, 233);
		font-size: small;
		border-radius: 5%;
	}
     .mask {
  background-color: rgb(199, 187, 187);
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100
}
    .pop {
  background-color: #fff;
  position: fixed;
  top: 300px;
  left: 500px;
  width: 400px;
  height:200px;
  z-index: 200
}
</style>

<script>
import TimeCount from "./TimeCount.vue";
import {QueryMemberTestPaper,QueryNextProblem,HandInPaper} from '../../api/data.js';
import global from '@/global';
export default {
   data(){
     return{
            height: {
            height: window.innerHeight-100 + 'px'
        },
        height_content:{
            height_content: window.innerHeight-160 + 'px'
        },
        time_length:1,
        TestPaperId:'', //试卷号
       MemberTestId:'', //用户测试号
       currTestIndex:0, //当前的题目索引
       currCheckedStyle:'', //当前焦点样式
       showModal: false,
       LastAnswer:'', //上次选择的答案
       grades: 0,
       paperName:'',
       problemNums:0, //题目数量
       totalScore:100, //总分数
       btm:'2021-12-12 23:23:30', //开始考试时间
       etm:'2021-12-12 23:59:30', //结束考试时间
       arrProblems:[]
    }
  },
   components:{
    TimeCount
   },
   mounted(){
     //禁用浏览器返回键
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.disableBrowserBack);
      //document.documentElement.webkitRequestFullScreen(); //全屏
      this.update();//在html加载完成后进行，相当于在页面上同步显示后端数据
   },
   created(){
     this.$nextTick(() => {
        // 禁用右键
        document.oncontextmenu = new Function("event.returnValue=false");
        // 禁用选择
        document.onselectstart = new Function("event.returnValue=false");
      });
      
   },
   methods:{
     doHandWork(){
        //交卷
        var arr = new Array();
        var ho;
        for (var i=0;i<this.$data.arrProblems.length;i++)
        {
            ho = new Object();
            ho.exam_pkid = this.$data.arrProblems[i].arrExams[0].exam_pkid;
            ho.group_pkid = this.$data.arrProblems[i].arrExams[0].group_pkid;
            ho.seq_group = this.$data.arrProblems[i].arrExams[0].seq_group;
            ho.member_answers = this.$data.arrProblems[i].arrExams[0].arrAnswers.toString().replace(/,/g,'');
            arr.push(ho);
        }
        
        let obj = {
          userId:global.machineId,
          testPaperId:this.TestPaperId,
          testPaperMemberId:this.MemberTestId,
          arrProblem:arr
        } 
        let that = this; //很重要
        
        HandInPaper(obj).then(function(res){
          //alert(res.data);
          //清除试卷存储
          global.clearMyDoingPaper();
          that.showModal = false;
          that.$router.push({
                  path:'/TestPaperResult',
                  name:'TestPaperResult',
                  params:{
                    id:that.TestPaperId,
                    testId:that.MemberTestId
                  }
                });
        });
     },
     doHandIn(){
       this.showModal = true;
     },
     update(){
       //判断是否存在未提交的试卷
       var running = global.getMyDoingPaper();
       this.TestPaperId = running.id;
       var testId = running.testId;
      //  this.TestPaperId = this.$route.query.id;
      //  var testId = this.$route.query.testId;
       //调用后台查询试卷信息
      let obj = {
         userId:global.machineId,
         testPaperId:this.TestPaperId
      }
      let that = this; //很重要
      
      QueryMemberTestPaper(obj).then(function(res){
        //that.arrProblems = res.data.arrProblems;
        that.paperName=res.data.paperName;
        that.totalScore = res.data.totalScore;
        that.MemberTestId = testId;
        that.time_length = res.data.time_length;
        that.problemNums = res.data.ProblemNums;
        var havaDoProblems = 0;
        for(var i=0;i<res.data.arrProblems.length;i++)
        {
          var problem = res.data.arrProblems[i];
          problem.arrExams[0].arrAnswers = new Array();
          if (i==0)
              problem.arrExams[0].show="background: #D37116;";
          else
              problem.arrExams[0].show="background: #D7D7D7;";
          that.arrProblems.push(problem);
        }
        //将试卷写入本地存储
        //if (running.checked==null||running.checked==undefined)
        //{
         //  running = new Object();
         //  running.id = that.TestPaperId;
         //  running.testId = res.data.MemberTestId;
           running.arrProblems = Array(res.data.ProblemNums);
         //  running.showType = 1647; //显示类型
           global.setMyDoingPaper(running);
        //} 
        
        var t = "已做"+havaDoProblems+"/共"+(that.problemNums)+"题";
        that.$refs.TimeCount.showTitle(t);
        that.$refs.TimeCount.beginTiming(that.time_length);
      });
     },
     GetNextProblem(){
       if (this.currTestIndex>=this.arrProblems.length-1)
          return;
       let obj = {
        userId:global.machineId,
        testPaperId:this.TestPaperId,
        testPaperMemberId:this.MemberTestId,
        problemId:this.arrProblems[this.currTestIndex+1].seq_group
      }
      let that = this; //很重要
       QueryNextProblem(obj).then(function(res){
        that.arrProblems[that.currTestIndex].title="*";
        that.arrProblems[that.currTestIndex].arrExams[0].exams="";
        that.arrProblems[that.currTestIndex].arrExams[0].arrResults=[];
        if (that.$data.arrProblems[that.currTestIndex].arrExams[0].arrAnswers.length == 0)
        {
          that.$data.arrProblems[that.currTestIndex].arrExams[0].show = "background: #D7D7D7;";
        }
        else
          that.$data.arrProblems[that.currTestIndex].arrExams[0].show = 'background: #00ABEA;';
        that.arrProblems[that.currTestIndex+1].title=res.data.arrProblems[0].title;
        that.arrProblems[that.currTestIndex+1].arrExams[0].exams=res.data.arrProblems[0].arrExams[0].exams;
        that.arrProblems[that.currTestIndex+1].arrExams[0].arrResults=res.data.arrProblems[0].arrExams[0].arrResults
        that.arrProblems[that.currTestIndex+1].arrExams[0].show = 'background: #D37116;';
        that.currTestIndex++;
         
      });
     },
     GetPrevProblem(){
       if (this.currTestIndex<1)
          return;
       let obj = {
        userId:global.machineId,
        testPaperId:this.TestPaperId,
        testPaperMemberId:this.MemberTestId,
        problemId:this.arrProblems[this.currTestIndex-1].seq_group
      }
      let that = this; //很重要
       QueryNextProblem(obj).then(function(res){
        that.arrProblems[that.currTestIndex].title="*";
        that.arrProblems[that.currTestIndex].arrExams[0].exams="";
        that.arrProblems[that.currTestIndex].arrExams[0].arrResults=[];
        if (that.$data.arrProblems[that.currTestIndex].arrExams[0].arrAnswers.length == 0)
        {
          that.$data.arrProblems[that.currTestIndex].arrExams[0].show = "background: #D7D7D7;";
        }
        else
          that.$data.arrProblems[that.currTestIndex].arrExams[0].show = 'background: #00ABEA;';
        that.arrProblems[that.currTestIndex-1].title=res.data.arrProblems[0].title;
        that.arrProblems[that.currTestIndex-1].arrExams[0].exams=res.data.arrProblems[0].arrExams[0].exams;
        that.arrProblems[that.currTestIndex-1].arrExams[0].arrResults=res.data.arrProblems[0].arrExams[0].arrResults
        that.arrProblems[that.currTestIndex-1].arrExams[0].show = 'background: #D37116;';
        that.currTestIndex--;
      });
     },
     GetProblem(idx){
       if (idx==this.currTestIndex)
         return;
       let obj = {
        userId:global.machineId,
        testPaperId:this.TestPaperId,
        testPaperMemberId:this.MemberTestId,
        problemId:this.arrProblems[idx].seq_group
      }
      let that = this; //很重要
       QueryNextProblem(obj).then(function(res){
        that.arrProblems[that.currTestIndex].title="*";
        that.arrProblems[that.currTestIndex].arrExams[0].exams="";
        that.arrProblems[that.currTestIndex].arrExams[0].arrResults=[];
        if (that.$data.arrProblems[that.currTestIndex].arrExams[0].arrAnswers.length == 0)
        {
          that.$data.arrProblems[that.currTestIndex].arrExams[0].show = "background: #D7D7D7;";
        }
        else
          that.$data.arrProblems[that.currTestIndex].arrExams[0].show = 'background: #00ABEA;';
        that.currTestIndex=idx;
        that.arrProblems[that.currTestIndex].title=res.data.arrProblems[0].title;
        that.arrProblems[that.currTestIndex].arrExams[0].exams=res.data.arrProblems[0].arrExams[0].exams;
        that.arrProblems[that.currTestIndex].arrExams[0].arrResults=res.data.arrProblems[0].arrExams[0].arrResults
        that.arrProblems[that.currTestIndex].arrExams[0].show = 'background: #D37116;';
      });
     },
     countDoAnswer(obj){
       //统计已经做了多少到题
       var n = 0;
       
       var total = obj.problemNums;
       var running = global.getMyDoingPaper();
       
         for(var j=0;j<obj.$data.arrProblems.length;j++)
         {
            
            if (obj.$data.arrProblems[j].arrExams[0].arrAnswers.length>0)
                n++;
            running.arrProblems[j] = obj.$data.arrProblems[j];
         }
         
       
      
       global.setMyDoingPaper(running);

       var t = "已做"+n+"/共"+(total)+"题";
       obj.$refs.TimeCount.showTitle(t);
     },
     Submit(i,idx){
       var wei = "classabc" + idx;
       var el = document.getElementsByClassName(wei)[0];
       console.log(el.offsetTop);
       this.$nextTick(function(){
         window.scrollTo({
            "behavior": "smooth",
            "top": el.offsetTop
         });
       });
     },
     selchange(index,dixx){
      // if (this.$data.arrProblems[index].arrExams[dixx].arrAnswers.length == 0)
      // {
      //   this.$data.arrProblems[index].arrExams[dixx].show = "background: #D7D7D7;";
      // }
      // else
      //   this.$data.arrProblems[index].arrExams[dixx].show = 'background: #00ABEA;';
      this.$options.methods.countDoAnswer(this); //统计已做题目
     },
    selSinglechange(aid,index,dixx){
      
      // alert("cg"+this.$data.LastAnswer+"-"+this.$data.checked[aid]);
      if (this.$data.checked[aid]==null||this.$data.checked[aid]==undefined||this.$data.checked[aid]=='')
      {
        this.$data.checkedStyle[aid] = "";
        this.currCheckedStyle=this.$data.checkedStyle[aid];
      }
      else
      {
        this.$data.checkedStyle[aid] = 'background: #00ABEA;'; 
            
      }
      //this.$data.LastAnswer = this.$data.checked[aid];
      this.currCheckedStyle=this.$data.checkedStyle[aid];
      this.$options.methods.countDoAnswer(this); //统计已做题目
      
    },
    click(aid,index,dixx){
      
      //alert(this.$data.LastAnswer+"-"+this.$data.checked[aid]);
      if (this.$data.checked[aid]==null||this.$data.checked[aid]==undefined||this.$data.checked[aid]=='')
      {
        this.$data.checkedStyle[aid] = "";
        this.currCheckedStyle=this.$data.checkedStyle[aid];
      }
      else
      {
        //判断是否与之前的答案一样
        //
        if (this.$data.LastAnswer==this.$data.checked[aid]&&
        (this.$data.datalist[index].members[dixx].examtype=='S'||this.$data.datalist[index].members[dixx].examtype=='C'))
         {
             this.$data.checked[aid]="";
             this.$data.checkedStyle[aid] = "";
             this.currCheckedStyle=this.$data.checkedStyle[aid];
         } 
      } 
    },
    Fillchange(aid,index,dixx){
      this.$data.datalist[index].members[dixx].edit = !this.$data.datalist[index].members[dixx].edit;
      var txt="";
      for (var i=0;i<this.$data.datalist[index].members[dixx].result.length;i++)
      {
        txt += this.$data.datalist[index].members[dixx].result[i].outcome;
      }
      
      if (txt==null||txt==undefined||txt=='')
      {
        this.$data.datalist[index].members[dixx].show = "";
      }
      else
        this.$data.datalist[index].members[dixx].show = 'background: #00ABEA;';
    },
    Answerchange(aid,index,dixx){
      
      this.$data.datalist[index].members[dixx].edit = !this.$data.datalist[index].members[dixx].edit;
      var txt = this.$data.datalist[index].members[dixx].myAnswer;
      if (txt==null||txt==undefined||txt=='')
      {
        this.$data.datalist[index].members[dixx].show = "";
      }
      else
        this.$data.datalist[index].members[dixx].show = 'background: #00ABEA;';
    },
     PritfGrade(){
       let a;
       for (a in this.$data.chapters.datalist){
         if (this.$data.datalist[a].answer === this.$data.datalist[a].radio){
           this.$data.grades = this.$data.grades + 1;
         }
       }
       alert("提交成功");
     }
     
   }
}
</script>